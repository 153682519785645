
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.pagination {
    margin: 0;
}
.glyphicon-2x {
    font-size: 24px;
}
#waiver {
    min-height: 600px;
    overflow: auto;
    width: 100%;
}
.padded {
    padding: 0 15px;
}
.panel {
    .table-responsive, 
    .dataTables_wrapper {
        padding: 0 15px;
    }
}
.scrollable {
    overflow-y: auto;

    &-400 {
        height: 400px;
    }
    &-600 {
        height: 600px;
    }
    &-800 {
        height: 800px;
    }
}

.loader {
    border: 2px solid $brand-success;
    border-radius: 4px;
    display: none;
    height: 25px;
    width: 100%;

    &-bar {
        background: $brand-success;
        height: 21px;
        width: 50%;
    }
}